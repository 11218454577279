<template>
  <el-container v-if="info.id">
    <el-main class="left">
      <div class="order-info">
        <div class="order-info-top">
          <div class="title">订单信息</div>
          <el-tag size="small" style="margin-left: 20px; background-color: green; color: #fff">{{ info.type == 1 ? '平台' : info.type == 2 ? '物业'  : info.type == 3 ? '技工' : '--' }}</el-tag>
        </div>
        <div class="content">
          <ul class="order-num">
            <li>
              订单编号：
              <span>{{ info.order_sn }}</span>
            </li>
          </ul>
          <div class="handle-progress" v-if="progress_status1">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">1</div>
              <p class="handle-span" style="color: #409eff;text-align: center">提交订单</p>
              <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">2</div>
              <p class="handle-span" style="color: #409eff;text-align: center">订单关闭</p>
              <p class="handle-time">{{ getDateformat(info.close_time) }}</p>
            </div>
          </div>
          <div class="handle-progress" v-if="progress_status2">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">1</div>
              <p class="handle-span" style="color: #409eff;text-align: center">提交订单</p>
              <p class="handle-time">{{ info.create_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">2</div>
              <p class="handle-span" style="color: #409eff;text-align: center">在线支付</p>
              <p class="handle-time">{{ info.deposit_pay_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">3</div>
              <p class="handle-span" style="color: #409eff;text-align: center">订单关闭</p>
              <p class="handle-time">{{ info.close_time }}</p>
            </div>
          </div>
          <div class="handle-progress" v-if="progress_status3">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">1</div>
              <p class="handle-span" style="color: #409eff;text-align: center">提交订单</p>
              <p class="handle-time">{{ info.create_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">2</div>
              <p class="handle-span" style="color: #409eff;text-align: center">在线支付</p>
              <p class="handle-time">{{ info.deposit_pay_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">3</div>
              <p class="handle-span" style="color: #409eff;text-align: center">商品发货</p>
              <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">4</div>
              <p class="handle-span" style="color: #409eff;text-align: center">订单关闭</p>
              <p class="handle-time">{{ info.close_time }}</p>
            </div>
          </div>

          <div class="handle-progress" v-if="progress_status4">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">1</div>
              <p class="handle-span" style="color: #409eff;text-align: center">提交订单</p>
              <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">2</div>
              <p class="handle-span" style="color: #409eff;text-align: center">付款时间</p>
              <p class="handle-time">{{ getDateformat(info.deposit_pay_time)  }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">3</div>
              <p class="handle-span" style="color: #409eff;text-align: center">接单时间</p>
              <p class="handle-time">{{ getDateformat(info.artisan_accept_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">4</div>
              <p class="handle-span" style="color: #409eff;text-align: center">上门时间</p>
              <p class="handle-time">{{ getDateformat(info.door_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">4</div>
              <p class="handle-span" style="color: #409eff;text-align: center">师傅确认完成时间</p>
              <p class="handle-time">{{ getDateformat(info.mechanic_finish_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">4</div>
              <p class="handle-span" style="color: #409eff;text-align: center">用户支付尾款</p>
              <p class="handle-time">{{ getDateformat(info.final_pay_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">4</div>
              <p class="handle-span" style="color: #409eff;text-align: center">用户签字</p>
              <p class="handle-time">{{ getDateformat(info.sign_time) }}</p>
            </div>
          </div>

          <div class="handle-progress" v-if="!progress_status1 && !progress_status2 && !progress_status3 && !progress_status4">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center">1</div>
              <p class="handle-span" style="color: #409eff;text-align: center">提交订单</p>
              <p class="handle-time">{{ getDateformat(info.create_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center" :style="{ background: info.deposit_pay_time ? '#409eff' : '' }">2</div>
              <p class="handle-span" style="color: #409eff;text-align: center" :style="{ color: info.deposit_pay_time ? '#409eff' : '' }" >付款时间</p>
              <p class="handle-time">{{ info.deposit_pay_time?getDateformat(info.deposit_pay_time):''  }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center" :style="{ background: info.artisan_accept_time ? '#409eff' : '' }">3</div>
              <p class="handle-span" style="color: #409eff;text-align: center" :style="{ color: info.artisan_accept_time ? '#409eff' : '' }" >接单时间</p>
              <p class="handle-time">{{ info.artisan_accept_time?getDateformat(info.artisan_accept_time):'' }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center" :style="{ background: info.door_time ? '#409eff' : '' }">4</div>
              <p class="handle-span" style="color: #409eff;text-align: center" :style="{ color: info.door_time ? '#409eff' : '' }">上门时间</p>
              <p class="handle-time">{{ info.door_time?getDateformat(info.door_time):'' }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center" :style="{ background: info.mechanic_finish_time ? '#409eff' : '' }">5</div>
              <p class="handle-span" style="color: #409eff;text-align: center" :style="{ color: info.mechanic_finish_time ? '#409eff' : '' }" >师傅确认完成时间</p>
              <p class="handle-time">{{ info.mechanic_finish_time?getDateformat(info.mechanic_finish_time):'' }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center" :style="{ background: info.final_pay_time ? '#409eff' : '' }">6</div>
              <p class="handle-span" style="color: #409eff;text-align: center" :style="{ color: info.final_pay_time ? '#409eff' : '' }">用户支付尾款</p>
              <p class="handle-time">{{ info.final_pay_time?getDateformat(info.final_pay_time):'' }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff;text-align: center" :style="{ background: info.sign_time ? '#409eff' : '' }">7</div>
              <p class="handle-span" style="color: #409eff;text-align: center"  :style="{ color: info.sign_time ? '#409eff' : '' }">用户签字</p>
              <p class="handle-time">{{ info.sign_time?getDateformat(info.sign_time) : '' }}</p>
            </div>
          </div>
          <div class="handle-status">
            <div class="status" v-if="info.goods_type == 2" style="color: #e6a23c">

            </div>
            <div class="status" v-else>订单状态： {{
                info.status == 0 ? '已关闭' :
                info.status == 10 ? '待支付' :
                info.status == 20 ? '待接单' :
                info.status == 30 ? '待上门' :
                info.status == 40 ? '已上门' :
                 info.status == 50 ? '待确认' :
                info.status == 60 ? '待签字' :
                info.status == 70 ? '已完成' :
                info.status == 80 ? '已完成' :
                    info.status == -10 ? '已关闭' :
                        info.status == -20 ? '已关闭' :
                  '--' }}</div>
            <div style="margin-bottom: 20px"></div>
            <el-button type="primary" @click="onSaveCanvas" v-if="info.status == 70 || info.status == 80">下载签字签单</el-button>
            <el-button v-if="info.status==20" type="primary" size="mini" @click="confirmReceipt(info)">派单</el-button>
            <el-button v-if="info.status==50" type="text" >尾款金额:{{info.final_total_price}}</el-button>

            <div class="btn" v-if="info.state == 30">
              <el-button type="primary" @click="confirmReceipt">确认收货</el-button>
            </div>
            <div class="btn" v-if="info.goods_type == 2 && info.group_state == 3 && info.state == 20">
              <el-button type="primary" @click="(showOrderSend = !0), (orderSendType = 1)">发货</el-button>
            </div>
            <div class="btn" v-if="info.state == 20 && info.goods_type != 2">
              <el-button type="primary" @click="(showOrderSend = !0), (orderSendType = 1)">发货</el-button>
            </div>
            <div class="order-actions" v-if="info.state > 0">
              <el-button type="span" @click="print">打印订单</el-button>
              <el-button type="span" @click="showMessage = !0">备注</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="buyers-info">
        <div class="title">买家信息</div>
        <div class="content">
          <div>
            <p>
              下单人账号：
              <span>{{ info.name || '--' }}</span>
            </p>
            <p>
              下单人姓名：
              <span>{{ info.name || '--' }} </span>
            </p>
            <p>
              下单人电话：
              <span>{{ info.mobile }}</span>
            </p>
          </div>
          <!-- <div v-if="!info.extend_order_extend.form_id">
            <p>
              下单人电话：
              <span>{{ info.extend_order_extend.reciver_infos.phone }}</span>
            </p>
          </div> -->
        </div>
      </div>
      <div class="goods-info">
        <div class="title">服务信息</div>
        <div class="content">
          <el-table :data="info.extend_order_goods" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column prop="goods_img" label="服务信息" align="center" width="300px">
              <template slot-scope="scope">
                <div class="goods-title">
                  <el-image :src="scope.row.service_cover_picture"></el-image>
                  <p>
                    {{ scope.row.service_name }}
                    <el-tag style="margin-left: 10px" type="danger" v-show="scope.row.room_id > 0">
                      <i class="iconfont icon-shouji" style="font-size: 14px"></i>
                      直播
                    </el-tag>
                    <el-tag type="danger" style="color: #f56ca7; border: 1px solid #ffacd0; background: #fff2f8" v-show="info.goods_type == 2">
                      <div style="display: flex; align-items: center">
                        <img style="margin-right: 5px" src="@/assets/image/group-icon.png" alt="" />
                        拼团
                      </div>
                    </el-tag>
                    <el-tooltip v-show="scope.row.distribution_infos" placement="top">
                      <el-tag type="warning">
                        <div style="display: flex; align-items: center">
                          <img style="margin-right: 5px" src="@/assets/distribution-icon.png" alt="" />
                          分销
                        </div>
                      </el-tag>
                      <template slot="content">
                        <p v-for="(distributionInfo, y) in scope.row.distribution_infos" :key="y">
                          {{ distributionInfo.type == 1 ? '直接佣金' : distributionInfo == 2 ? '间接佣金' : '次间接佣金' }}：{{ distributionInfo.distribution_person.nickname }}（￥{{
                            distributionInfo.amount
                          }}）
                        </p>
                      </template>
                    </el-tooltip>
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="sku_list.title" label="服务类别" align="center">
              <template >
                  {{info.service_cate_name}}
              </template>
            </el-table-column>
            <el-table-column prop="goods_price" label="自定义规格" align="center">
              <template slot-scope="scope">{{scope.row.service_attr_str}}</template>
            </el-table-column>
          </el-table>
          <el-table :data="info.extend_order_goods" :header-cell-style="{ 'background-color': '#F8F9FA' }" style="margin-top: 20px">
            <el-table-column prop="goods_img" label="下单人" align="center" width="300px">
              <template >
                <div class="goods-title">
                  <p>
                    {{ info.name }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="sku_list.title" label="下单人电话" align="center">
              <template>
                  {{info.mobile}}
              </template>
            </el-table-column>
            <el-table-column prop="goods_price" label="预约时间" align="center">
              <template>{{getDateformat(info.order_time)}}</template>
            </el-table-column>
            <el-table-column prop="goods_num" label="服务区域" align="center"></el-table-column>
            <el-table-column prop="goods_num" label="服务详细地址" align="center">
              <template>{{info.address_info_d.address}}{{info.address_info_d.door_no}}</template>
            </el-table-column>
          </el-table>
          <div class="currentStation">
            <div class="currentPic">
              <span>现场图片</span>
              <ul v-for="(item, index) in info.images_d" :key="index">
                <el-image style="width: 50px; height: 50px" :src="item" :prediv-src-list="info.images_d"></el-image>
                <!-- <img src="../../assets/404.png" alt=""> -->
              </ul>
            </div>
            <div class="currentDesc">
              <span>描述</span>
              <p>{{info.description}}</p>
            </div>
          </div>
          <el-table v-if="info.artisan_data" :data="info.artisan_data" :header-cell-style="{ 'background-color': '#F8F9FA' }" style="margin-top: 20px">
            <el-table-column prop="goods_img" label="接单技工姓名" align="center" width="300px">
              <template >
                <div class="goods-title">
                  <p>
                      {{ info.artisan_data?info.artisan_data.artisan_name:'--' }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column  label="接单技工电话" align="center">
              <template>
                  {{ info.artisan_data.mobile?info.artisan_data.mobile:'--' }}
              </template>
            </el-table-column>
            <el-table-column  label="完成图" align="center">
              <template>
                <div class="currentPic">
                  <ul v-for="(item, index) in info.mechanic_finish_image_d" :key="index">
                    <el-image style="width: 50px; height: 50px" :src="item" :prediv-src-list="info.mechanic_finish_image_d"></el-image>
                  </ul>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <el-table :data="info.extend_order_goods" :header-cell-style="{ 'background-color': '#F8F9FA' }" style="margin-top: 20px">
            <el-table-column prop="goods_img" label="定金金额" align="center" width="300px">
              <template >
                <div class="goods-title">
                  <p>
                      {{ info.deposit_price }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column  label="定金支付方式" align="center">
              <template>
                  {{ info.deposit_pay_type ==10 ? '微信支付' : info.deposit_pay_type ==20 ?  '支付宝支付' : '--' }}
              </template>
            </el-table-column>
            <el-table-column  label="定金支付时间" align="center">
              <template>
                  {{getDateformat(info.deposit_pay_time)}}
              </template>
            </el-table-column>
            <el-table-column  label="尾款金额" align="center">
              <template>
                  {{ info.final_total_price }}
                <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="
                        工时费:2,
                        工作时间:1">
                  <el-button slot="reference" size="mini" type="text">
                    <i class="el-icon-question"></i>
                  </el-button>
                  <div style="span-align: center; margin: 0">
                     <p>工时费:{{info.service_data.one_hour_cost}}</p>
                     <p>工作时间:{{ info.mechanic_time }}</p>
                     <p>工作费用: {{info.service_data.one_hour_cost}}  * ( {{ info.mechanic_time }} - {{ info.vip_no_hour_cost }} )</p>
                     <p>
                        维修费用清单:
                        <ul v-for="(item, index) in info.mechanic_material_d" :key="index">
                           <li>名称: {{item.name}} 价格: {{item.price}} 数量: {{item.num}}</li>
                        </ul>
                     </p>
                     <p>尾款: {{ info.final_pay_price }}</p>
                     <p>优惠券减免: {{ info.fianl_reduce_price }}</p>
                  </div>
                </el-popover>

              </template>
            </el-table-column>
            <el-table-column  label="尾款支付方式" align="center">
              <template>
                  {{ info.final_pay_type ==10 ? '微信支付' : info.final_pay_type ==20 ?  '支付宝支付' : '--' }}
              </template>
            </el-table-column>
            <el-table-column  label="尾款支付时间" align="center">
              <template>
                  {{getDateformat(info.final_pay_time)}}
              </template>
            </el-table-column>
            <el-table-column  label="总计金额" align="center">
              <template>
                  {{ info.total_price }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-main>
    <el-main v-if="forminfo" class="logistics-address">
      <!-- 报名信息 -->
      <div class="formdata">
        <div class="title">报名信息</div>
        <div class="row" style="margin-bottom: 10px">
          <div class="row_left">报名人：</div>
          <div class="row_right">{{ info.user_name }}</div>
        </div>
        <template v-for="(item, index) in forminfo">
          <div class="row" :key="index" v-if="item.type != 'banner' && item.type != 'protocol'">
            <div class="row_left">{{ item.info.name }}：</div>
            <div class="row_right" v-if="item.type == 'checkbox' || item.type == 'area'">{{ item.value.toString() }}</div>
            <div class="row_right" v-else-if="item.type == 'image'">
              <el-image :src="i_item" v-for="(i_item, i_index) in item.value" :key="i_index" :prediv-src-list="item.value"></el-image>
            </div>
            <div class="row_right" v-else-if="item.type == 'daterange'">{{ item.valueStart }} - {{ item.valueEnd }}</div>
            <div class="row_right" v-else>{{ item.value }}</div>
          </div>
        </template>
      </div>
    </el-main>

    <!-- <el-main class="logistics-address" v-if="!info.header_id || info.consignee_time == '0'">
      <div class="title">
        物流信息
        <el-button v-if="info.state == 30" @click="(showOrderSend = !0), (orderSendType = 0)" type="span">修改</el-button>
      </div>
      <div class="sn" v-if="company_name">{{ company_name }}：{{ tracking_no1 }}</div>
      <template v-if="logisticsInfo">
        <el-timeline :reverse="reverse">
          <el-timeline-item v-for="(item, index) in logisticsInfo.data" :key="index" :timestamp="item.time">
            {{ item.conspan }}
          </el-timeline-item>
        </el-timeline>
      </template>
      <template v-else>
        <No />
      </template>
    </el-main>

    <el-dialog title="备注" :visible.sync="showMessage" width="35%">
      <el-form v-if="info.id" label-width="85px">
        <el-form-item label="订单编号：">{{ info.pay_sn }}</el-form-item>
        <el-form-item label="总价：">{{ info.amount }}</el-form-item>
        <el-form-item label="收货人：">{{ info.extend_order_extend.reciver_infos.name }}</el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="info.system_message" type="spanarea" :rows="8" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showMessage = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmSetSystemMessage">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="this.orderSendType ? '发货' : '修改物流'" :visible.sync="showOrderSend" width="600px">
      <el-form label-width="90px" v-if="info.id">
        <el-form-item label="收货人：">{{ info.extend_order_extend.reciver_infos.name }}</el-form-item>
        <el-form-item label="收货地址：">{{ info.extend_order_extend.reciver_infos.combine_detail }}{{ info.extend_order_extend.reciver_infos.address }}</el-form-item>
        <el-form-item label="快递公司：">
          <el-select v-model="express_id" filterable placeholder="请选择">
            <el-option v-for="item in logisticsList" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号：">
          <el-input v-model="tracking_no"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showOrderSend = !1">取 消</el-button>
        <el-button type="primary" @click="confirmSend">确 定</el-button>
      </span>
    </el-dialog> -->

<el-dialog title="派单" :visible.sync="paidan" width="50%">
    <ul class="cardUl">
      <li>
        <div class="cardLi">
          <div class="content cardLiTab">
            <el-form ref="form" :model="form" label-width="100px" label-position="left">
              <el-form-item label="付款人：">
                <span>{{info.name}}/{{info.mobile}}</span>
              </el-form-item>
              <el-form-item label="服务时间：">
                <span>{{getDateformat(info.order_time)}}</span>
              </el-form-item>
              <el-form-item label="服务地址：">
                  <span>{{info.address_info_d.address}} {{info.address_info_d.door_no}}</span>
              </el-form-item>
              <el-form-item label="服务类型：" required="true">
                <el-select v-model="form.type" placeholder="请选择" @change="(val) => getartisan(0, val)">
                  <el-option label="平台" value="1"></el-option>
                  <el-option label="物业" value="2"></el-option>
                  <el-option label="自由" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="服务小区" required="true">
                <el-select size="small" v-model="form.classify_id" placeholder="请选择或搜索" @change="(val) => getartisan(0, val)">
                    <el-option v-for="item in community" :key="item.id" :label="item.community_name"
                        :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="技工师傅" required="true">
                <el-select size="small" v-model="form.artisan_id" placeholder="请选择或搜索">
                    <el-option v-for="item in artisan" :key="item.id" :label="item.artisan_name"
                        :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="formBtn">
                <el-button @click="paidan = false">取消</el-button>
                <el-button type="primary" @click="onSubmit">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </li>
    </ul>
</el-dialog>

    <div class="container" style="opacity: 0;">
      <div id="screenshot-box" style="position: fixed">
        <!-- 定频立式空调外室机维修 -->
        <div class="orderinfo">
          <div class="til">{{info.service_name}}</div>
          <ul>
            <li>
              <span style="color: #999999;">订单编号：</span>
              <span>{{info.order_sn}}</span>
            </li>
            <li>
              <span style="color: #999999;">维修时间：</span>
              <span>{{info.order_time}}</span>
            </li>
            <li>
              <span style="color: #999999;">下单联系人：</span>
              <span>{{info.name}}</span>
              <el-image v-if="info.is_vip" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/vipLogo.png" mode="" style="width: 52px;height: 28px;margin-left: 20px;"></el-image>
            </li>
            <li>
              <span style="color: #999999;">联系电话：</span>
              <span>{{info.mobile}}</span>
            </li>
            <li v-if="info.artisan_data">
              <span style="color: #999999;">技工姓名：</span>
              <span>{{info.artisan_data.artisan_name}}</span>
            </li>
            <li  v-if="info.artisan_data">
              <span style="color: #999999;">技工电话：</span>
              <span>{{info.artisan_data.mobile}}</span>
            </li>
            <li>
              <span style="color: #999999;">付款日期：</span>
              <span>{{getDateformat(info.deposit_pay_time)}}</span>
            </li>

          </ul>
        </div>

        <!-- 人工费用 -->
        <div class="orderinfo">
          <!-- 人工费用 -->
          <div class="laborCost">
            <div class="laborCostTil">
              <div class="til" style="margin-bottom: 16px;width: 500px">人工费用
              </div>
              <div class="til" style="margin-bottom: 16px;color:#FF982E"
                v-if="info.mechanic_time<=info.vip_no_hour_cost && info.is_vip">
                免费</div>
              <div class="til" style="margin-bottom: 16px;" v-if="info.is_vip">
                ￥{{info.service_data.one_hour_cost * (info.mechanic_time-vip_no_hour_cost)}}</div>
              <div class="til" style="margin-bottom: 16px;" v-else>
                ￥{{info.service_data.one_hour_cost * (info.mechanic_time)}}</div>
            </div>
            <ul>
              <li style="margin-top: 0;justify-content: space-between;display: flex;">
                <div style="width: 454px;display: flex;">
                  <span style="width: 140px;">总计工时：</span>
                  <span style="width: 180px;">{{info.mechanic_time}}小时</span>
                </div>
                <span class="vipMoney" v-if="info.is_vip">VIP免费维修1小时</span>
              </li>
              <li style="margin-top: 24px;display: flex;">
                <span style="width: 140px;">开始时间：</span>
                <span>{{getDateformat(info.mechanic_start_time)}}</span>
              </li>
              <li style="display: flex;">
                <span style="width: 140px;">结束时间：</span>
                <span>{{getDateformat(info.mechanic_end_time)}}</span>
              </li>
            </ul>
          </div>
          <!-- 材料清单总费用 -->
          <div class="laborCost" style="margin-top: 36px;">
            <div class="laborCostTil" v-if="info.mechanic_material">
              <div class="til" style="margin-bottom: 16px;">材料清单总费用: </div>
              <div class="til" style="margin-bottom: 16px;"  v-if="info.mechanic_material_d">
                ￥{{info.mechanic_material_d.reduce((prev,item) => prev + item.price * item.num, 0)}}
              </div>
            </div>
            <ul class="materialCostUl" v-if="info.mechanic_material">
              <li v-for="item in info.mechanic_material_d" :key="item.id">
                <span style="span-align: left;">{{item.name}}</span>
                <span>x{{item.num}}</span>
                <span style="span-align: right;">￥{{item.price}}</span>
              </li>
            </ul>
            <div class="materialCostPrice">
              <div>
                <span>订单总价：</span>
                <span>￥{{info.total_price}}</span>
              </div>
              <div v-if="info.fianl_reduce_price*1">
                <span></span>
                <span style="color: #FF982E;">优惠：-￥{{info.fianl_reduce_price}}</span>
              </div>
              <div>
                <span>已付定金：</span>
                <span>￥{{info.deposit_price}}</span>
              </div>
              <div style="margin-bottom: 0;height: 30px;">
                <span>应付尾款：</span>
                <div
                  style="color: #F82222;font-size: 30px;font-weight: bold;" v-if="info.fianl_reduce_price">
                  ￥{{info.total_price - info.fianl_reduce_price - info.deposit_price}}</div>
                <div
                  style="color: #F82222;font-size: 30px;font-weight: bold;" v-else>
                  ￥{{info.total_price - info.deposit_price}}</div>
              </div>
            </div>
          </div>
          <div class="sign">
            <span style="width: 114px;">签字人：</span>
            <el-image :src="info.sign" mode="" style="width: 100px;height: 100px;"></el-image>
          </div>
        </div>
      </div>
    </div>
  </el-container>  
</template>

<script>
import { getDateformat } from '@/util/getDate';
import { mapState } from 'vuex';
import html2canvas from 'html2canvas';
export default {
  data() {
    return {
      orderInfo: {},
      id: '',
      info: {},
      goods_info: [],
      paidan:false,
      form: {},
      logisticsInfo: '',
      showMessage: !1,
      showOrderSend: !1,
      express_id: '', //快递公司
      tracking_no: '', //快递单号
      company_name: '',
      tracking_no1: '',
      orderSendType: 1,
      progress_status1: 0,
      progress_status2: 0,
      progress_status3: 0,
      progress_status4: 0,
      path: '',
      forminfo: null,
      // 图片
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: ['https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg', 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'],
    };
  },
  computed: {
    ...mapState({
      logisticsList: state => state.order.logisticsList, //物流公司列表
    }),
  },
  watch: {
    showOrderSend(val) {
      if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
    },
  },
  created() {
    let utils = this.$store.state.utils;
    utils.page = Number(this.$route.query.page);
    utils.rows = Number(this.$route.query.rows);
    utils.is_record = !0;
    this.id = this.$route.query.id;
    this.getOrderInfo();
    //this.logisticsQuery();
  },
  methods: {
    //点击保存为Canvas
    onSaveCanvas() {
      // 这里的类名要与点击事件里的一样
      const canvas = document.querySelector('#screenshot-box');
      let that = this;
      html2canvas(canvas, { scale: 2, logging: false, useCORS: true }).then(function (canvas) {
        const type = 'png';
        let imgData = canvas.toDataURL(type);
        // 图片格式处理
        let _fixType = function (type) {
          type = type.toLowerCase().replace(/jpg/i, 'jpeg');
          let r = type.match(/png|jpeg|bmp|gif/)[0];
          return 'image/' + r;
        };
        imgData = imgData.replace(_fixType(type), 'image/octet-stream');
        let filename = 'htmlImg' + '.' + type;
        // 保存为文件
        //  以bolb文件下载
        that.downFileToLocal(filename, that.convertBase64ToBlob(imgData));
      });
    },
    // base64转化为Blob对象
    convertBase64ToBlob(imageEditorBase64) {
      let base64Arr = imageEditorBase64.split(',');
      let imgtype = '';
      let base64String = '';
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1];
        imgtype = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';'));
      }
      // 将base64解码
      let bytes = atob(base64String);
      //let bytes = base64;
      let bytesCode = new ArrayBuffer(bytes.length);
      // 转换为类型化数组
      let byteArray = new Uint8Array(bytesCode);

      // 将base64转换为ascii码
      for (let i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i);
      }
      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], { type: imgtype });
    },
    // 下载Blob流文件
    downFileToLocal(fileName, blob) {
      // 创建用于下载文件的a标签
      const d = document.createElement('a');
      // 设置下载内容
      d.href = URL.createObjectURL(blob);
      // 设置下载文件的名字
      d.download = fileName;
      // 界面上隐藏该按钮
      d.style.display = 'none';
      // 放到页面上
      document.body.appendChild(d);
      // 点击下载文件
      d.click();
      // 从页面移除掉
      document.body.removeChild(d);
      // 释放 URL.createObjectURL() 创建的 URL 对象
      // window.URL.revokeObjectURL(d.href);
      this.comfirmsignBill(blob, fileName);
    },
    handleSales(val) {
      this.handle_state = val;
      this.showHandleSales = !0;
    },
          // 获取服务分类
    getCommunityList () {
        this.$axios.post(this.$api.repair.service.communityList).then(res => {
            if (res.code === 0) {
                this.community = res.result.list
            }
        });
    },
    getartisan(status, val){
        console.log(status)
        console.log(val)
        let data = this.form
        console.log(data)
        data.page = 1;
        data.rows = 10000;
        this.$axios.post(this.$api.repair.service.artisanList,data).then(res => {
            if (res.code === 0) {
                this.artisan = res.result.list
            }
        });
    },
    onSubmit(){
        if(!this.form.artisan_id) return
        let data = {
            id:this.info.id,
            artisan_id:this.form.artisan_id
        }
        this.$axios.post(this.$api.repair.service.orderDispatch,data).then(res => {
            if (res.code === 0) {
                this.community = res.result.list
                this.getOrderInfo()
                this.paidan = false
            }
        });
    },
    comfirmsignBill(url, fileName) {
      const blobToFile = new File([url], fileName, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });

      let formData = new FormData();
      formData.append('files', blobToFile);

      this.$axios.uploadFile(this.$api.user.addFiles, formData).then(res => {
        if (res.code == 0) {

          this.$axios
            .post(this.$api.repair.service.signBill, {
              id: this.id,
              sign_bill: res.result.path,
            })
            .then(res => {
              if (res.code == 0) {
                this.$message({
                  message: '下载成功',
                  type: 'success',
                });
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    comfirmSetSystemMessage() {
      this.$axios
        .post(this.$api.order.setSystemMessage, {
          id: this.id,
          system_message: this.info.system_message,
        })
        .then(res => {
          if (res.code == 0) {
            this.showMessage = !1;
            this.$message({
              message: '备注成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //发货
    confirmSend() {
      let orderInfo = this.info.extend_order_goods;
      for (let i in orderInfo) {
        if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可发货！');
      }
      if (!this.tracking_no) this.$message.warning('请填写快递单号');
      let data = {
        tracking_no: this.tracking_no,
        id: this.id,
        express_id: this.express_id,
      };
      if (this.orderSendType) data.need_express = 1;
      let url = this.orderSendType ? this.$api.order.setSend : this.$api.order.editTracking;
      this.$axios.post(url, data).then(res => {
        if (res.code == 0) {
          this.getOrderInfo();
          this.logisticsQuery();
          this.showOrderSend = !1;
          this.$message.success(this.orderSendType ? '发货成功' : '修改成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
   confirmReceipt (item){
      this.info = item
      this.paidan=true;
      this.getCommunityList();
    },
    //打印
    print() {
      let routeData = this.$router.resolve({
        path: '/print',
        query: {
          id: this.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    logisticsQuery() {
      this.$axios.post(this.$api.order.logisticsQuery, { id: this.id }).then(res => {
        if (res.code == 0 && res.result.info.returnCode != '400') {
          this.company_name = res.result.company_name;
          this.logisticsInfo = res.result.info;
          this.tracking_no1 = res.result.info.nu;
        } else if (res.code == -2) {
          this.tracking_no1 = res.result.tracking_no;
          this.company_name = res.result.company_name;
        }
      });
    },
    getOrderInfo() {
      // this.$axios.post(this.$api.order.info, { id: this.id }).then(res => {
      this.$axios.post(this.$api.repair.service.orderInfo, { id: this.id }).then(res => {
        if (res.code == 0) {
          let info = res.result;
          let stateArr = [10];
          // if (info.payment_time) stateArr.push(20);
          // if (info.cancel_time) stateArr.push(0);
          // if (info.extend_order_extend.tracking_time) stateArr.push(30);
          // if (info.finnshed_time) stateArr.push(40);
          // if (info.evaluate_time) stateArr.push(50);
          if (info.status == 0 || info.status == -10 || info.status == -20) this.progress_status1 = 1;
          //if ([0, -10,-20].sort().toString() == stateArr.sort().toString()) this.progress_status1 = 1;
          console.log(this.progress_status1);
          // if ([0, 10, 20].sort().toString() == stateArr.sort().toString()) this.progress_status2 = 1;
          // if ([0, 10, 20, 30].sort().toString() == stateArr.sort().toString()) this.progress_status3 = 1;
          // if ([0, 10, 20, 30, 40].sort().toString() == stateArr.sort().toString()) this.progress_status4 = 1;
          info.extend_order_goods = [info];
          this.info = info;
          console.log(info);
          // if (info.extend_order_extend.form_id) {
          //   this.getFormData();
          // }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    // 报名信息
    getFormData() {
      this.$axios.post(this.$api.customform.dataInfo, { id: this.info.extend_order_extend.form_id }).then(res => {
        if (res.code == 0) {
          this.forminfo = JSON.parse(res.result.form_data);
        } else {
          this.forminfo = null;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  font-family: Microsoft YaHei;
  font-size: 14px;
  color: #17171a;
}

.order-info-top {
  display: flex;
}

.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 20px;
}
.left {
  margin-right: 8px;
  background: #fff;
  .order-info {
    margin-bottom: 30px;
  }
  .order-info .content {
    .order-num {
      display: flex;
      height: 47px;
      background: #f0f0f5;
      align-items: center;
      padding-left: 24px;
      li {
        font-size: 14px;
        margin-right: 40px;
        span {
          font-weight: 400;
          color: #17171a;
        }
      }
    }
    .handle-progress {
      display: flex;
      justify-content: center;
      height: 190px;
      align-items: center;
      .line {
        height: 4px;
        width: 120px;
        background: #f0f0f5;
      }

      .item {
        background: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .num {
          width: 32px;
          height: 32px;
          line-height: 32px;
          span-align: center;
          background: #c8c8cc;
          border-radius: 50%;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        .handle-span {
          width: 100px;
          span-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -25px;
          font-size: 14px;
          font-weight: bold;
          color: #969699;
        }
        .handle-time {
          width: 120px;
          position: absolute;
          left: 50%;
          span-align: center;
          transform: translateX(-50%);
          bottom: -25px;
          font-size: 12px;
          font-weight: 400;
          color: #969699;
        }
      }
    }
    .handle-status {
      padding: 30px 0;
      background: #f0f0f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .status {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #969699;
      }
      .btn {
        margin-top: 18px;
      }
      .order-actions {
        margin-top: 10px;
      }
    }
  }
  .buyers-info {
    margin-bottom: 30px;
    .content {
      margin-top: 24px;
      padding-left: 40px;
      div {
        display: flex;
        span {
          font-weight: 400;
        }
        p {
          width: 240px;
          &:last-child {
            width: auto;
          }
        }
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .goods-info .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      margin-top: 20px;
      border-bottom: 1px solid #f5f5fa;
      color: #969699;
      span:last-child {
        color: #17171a;
        width: 80px;
        span-align: right;
      }
      p {
        display: flex;
        margin-bottom: 5px;
      }
    }
    .goods-title {
      display: flex;
      justify-content: center;
      align-items: center;
      .el-image {
        width: 68px;
        height: 68px;
        border-radius: 8px;
        margin-right: 8px;
      }
      p {
        flex: 1;
      }
    }
    .total-price {
      font-weight: bold;
      margin-top: 5px;
      display: flex;
      span:last-child {
        span-align: right;
        font-weight: 400;
        color: #fd5e94;
        width: 80px;
      }
    }
  }
}

.logistics-address {
  width: 480px;
  background: #fff;
  overflow: hidden;
  .sn {
    margin-bottom: 30px;
  }
  /deep/ .el-timeline-item__content {
    color: #17171a;
  }
}

.formdata {
  .row {
    display: flex;
    line-height: 36px;
    .row_left {
      width: 160px;
      span-align: right;
      flex-shrink: 0;
    }
    .row_right {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .el-image {
        height: 76px;
        width: auto;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
}

.currentStation {
  width: 100%;
  height: 200px;
  color: gray;
  margin-top: 20px;
}

.currentStation > div {
  height: 50%;
  display: flex;
  align-items: center;
}

.currentStation > div > span:nth-child(1) {
  width: 150px;
}

// 截取html样式
.orderinfo {
  margin: 0 28px;
  background-color: #fff;
  padding: 36px 26px;
  border-radius: 16px 16px 16px 16px;
  margin-bottom: 26px;

  li {
    display: flex;
    align-items: center;
    margin-top: 28px;
    color: #333333;
    font-size: 28px;
  }

  .laborCost span:nth-child(2) {
    font-weight: 400;
  }

  .laborCost {
    .laborCostTil {
      display: flex;
      justify-content: space-between;
    }

    ul {
      background-color: #f3f5f3;
      padding: 26px 20px;
      border-radius: 16px;

      li {
        margin-top: 12px;
      }
    }
  }

  .materialCostUl {
    li {
      display: flex;
      justify-content: space-between;
    }
  }

  .materialCostPrice {
    display: flex;
    flex-direction: column;
    color: #333333;
    font-size: 28px;
    margin-top: 46px;

    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;
    }
  }
}

.orderinfo span:nth-child(2) {
  font-weight: bold;
}

.orderinfo span:nth-child(1) {
  display: inline-block;
  width: 200px;
}

.materialCostUl span:nth-child(1) {
  width: 200px;
}

.til {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.sign {
  margin: 47px 0px;
}

.sign span:nth-child(1) {
  font-size: 28px;
}

.sign span:nth-child(2) {
  font-size: 30px;
  font-weight: bold;
}
.vipMoney {
  width: 300px !important;
  span-align: right;
  color: #ff982e;
}
</style>
